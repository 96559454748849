import { useEffect, useState } from "react";
import "./App.css";
import Form from "./components/Form";
import Items from "./components/Items";

function App() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        console.log(items);
    }, [items]);

    return (
        <div className="App p-5">
            <div className="max-w-full bg-orange-500 flex justify-center items-center rounded-md py-4 px-2">
                <div className="header">
                    <img
                        src="jetlogo.png"
                        className="w-40 md:w-60 lg:w-80"
                        alt="JET Logo"
                    />
                </div>
            </div>
            <div className="max-w-screen-lg m-auto bg-slate-100 rounded-md py-4 px-2 my-5">
                <h1 className="font-bold text-2xl text-slate-700">
                    Menu Finder
                </h1>
                <Form setItems={setItems} />
            </div>
            {items && items.length > 0 && <Items items={items} />}
            <div className="max-w-full text-center">
                <p>Made with 🧡 by Reece Devonport</p>
            </div>
        </div>
    );
}

export default App;
